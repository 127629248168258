import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCurrentBrand } from "@hooks";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import { workflow } from "../../configurator/models/Workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import GenericButton from "../common/GenericButton";
import "./default.module.scss";
import { isDesktop } from "react-device-detect";
import { virtualMirrorActions } from "../../redux/slices/virtualMirror";

interface ExitModalOpt {
  onContinueEditing: () => void;
  onExit: () => void;
  onSave: () => void;
}

export function ExitModal({ onContinueEditing, onExit, onSave }: ExitModalOpt) {
  const exitModalTitle = useTranslate("exitTitle");
  const exitModalSubtitle = useTranslate("exitSubtitle");
  const exitModalExitButton = useTranslate("exitYes");
  const exitModalContinueButton = useTranslate("exitContinueEditing");
  const exitModalSave = useTranslate("exitSave");

  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.progressive
  );
  const isSaveAndContinueDisabled = useSelector(
    (state: any) => state.config.layoutSettings?.disableSaveAndContinue
  );
  const isCostaBrand = useCurrentBrand() === "costa";
  const isDefaultBrand = useCurrentBrand() === "default";
  const dispatch = useDispatch();
  const getDataElementIdForBack = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-BackToProdConfirm";
  };
  const globalVmapp = useSelector(
    (state: any) => state.virtualMirror.globalVmapp
  );

  const handleCloseVmmv = () => {
    if (globalVmapp) {
      globalVmapp.unmount().then(() => {
        dispatch(virtualMirrorActions.setShowVirtualMirror(false));
      });
    }
  };

  const handleContinueEditing = () => {
    if (onContinueEditing) {
      onContinueEditing();
    }
  };

  const handleExit = () => {
    if (onExit) {
      handleCloseVmmv();
      onExit();
    }
  };

  const handleSave = () => {
    if (onSave) {
      onSave();
    }
  };

  const modalRef = useRef(null);

  const brand = useCurrentBrand();

  useEffect(() => {
    const modal = modalRef.current;
    const focusableElements = modal.querySelectorAll(
      'button, a, input, [tabindex="0"]'
    );
    const firstElement = focusableElements[0];
    const lastElement = focusableElements[focusableElements.length - 1];

    const handleKeyDown = (e) => {
      if (e.key === "Tab") {
        if (e.shiftKey) {
          if (document.activeElement === firstElement) {
            e.preventDefault();
            lastElement.focus();
          }
        } else {
          if (document.activeElement === lastElement) {
            e.preventDefault();
            firstElement.focus();
          }
        }
      } else if (e.key === "Escape") {
        onExit();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    firstElement.focus();

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [onExit]);


  return (
    <div className={"ExitModal__container"}>
      <div className={"ExitModal__container__overlay"}></div>
      <div className={"ExitModal__container__popup"} ref={modalRef} role="dialog" aria-labelledby="dialog-title" aria-describedby="dialog-desc" aria-modal="true">
        {brand === "framesdirect" && (
          <svg
            onClick={handleExit}
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <g clip-path="url(#clip0_235_100770)">
              <path
                d="M15.7992 1.13255L14.8659 0.199219L7.99922 7.06589L1.13255 0.199219L0.199219 1.13255L7.06589 7.99922L0.199219 14.8659L1.13255 15.7992L7.99922 8.93255L14.8659 15.7992L15.7992 14.8659L8.93255 7.99922L15.7992 1.13255Z"
                fill="black"
              />
            </g>
            <defs>
              <clipPath id="clip0_235_100770">
                <rect width="16" height="16" fill="white" />
              </clipPath>
            </defs>
          </svg>
        )}
        <div className={"ExitModal__container__title"} tabIndex={0} id="dialog-title">{exitModalTitle}</div>
        {!isCostaBrand &&
          !isDefaultBrand &&
          currentStep &&
          (!isSaveAndContinueDisabled ||
            isSaveAndContinueDisabled !== true) && (
            <div
              className={"ExitModal__container__subtitle"}
              id="dialog-desc"
              tabIndex={0}
              style={{
                display:
                  currentStep ===
                    workflow.getMaxStep(workflow.stepChain).progressive
                    ? "none"
                    : "block",
              }}
            >
              {exitModalSubtitle}
            </div>
          )}
        <div
          className={"ExitModal__container__buttonsContainer"}
          style={{
            marginTop:
              isDefaultBrand &&
              isDesktop &&
              currentStep ===
              workflow.getMaxStep(workflow.stepChain).progressive &&
              "24px",
          }}
        >
          <GenericButton
            noArrow={true}
            className={"ExitModal__container__buttonsContainer__buttonExit"}
            handleClick={handleExit}
            dataElementId={getDataElementIdForBack()}
            aria-label={exitModalExitButton}
          >
            {exitModalExitButton}
          </GenericButton>
          <GenericButton
            noArrow={true}
            className={
              "ExitModal__container__buttonsContainer__buttonContinue " +
              (currentStep !==
                workflow.getMaxStep(workflow.stepChain).progressive
                ? " marginBtm"
                : "")
            }
            handleClick={handleContinueEditing}
            aria-label={exitModalContinueButton}
          >
            {exitModalContinueButton}
          </GenericButton>
        </div>
        {currentStep &&
          currentStep === workflow.getMaxStep(workflow.stepChain).progressive &&
          (!isSaveAndContinueDisabled ||
            isSaveAndContinueDisabled !== true) && (
            <a className={"ExitModal__container__save"} onClick={handleSave}>
              {exitModalSave}
            </a>
          )}
        {isDefaultBrand && (
          <div
            className={"ExitModal__container__subtitle"}
            style={{
              marginTop: "8px",
              display:
                currentStep ===
                  workflow.getMaxStep(workflow.stepChain).progressive
                  ? "block"
                  : "none",
            }}
          >
            {exitModalSubtitle}
          </div>
        )}
      </div>
    </div>
  );
}
