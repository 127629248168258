import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { workflow } from "../../configurator/models/Workflow";
import { BrandComponent } from "../common/BrandComponent";
import { useCurrentBrand, useCurrentPrices, useMixAndMatch, useShowFramePlusLensesPrice } from "@hooks";
import { useSelector } from "react-redux";
import { useBundlesPrices } from "../../configurator/hooks/useBundlesPrices";

interface HeaderPriceProps { }

/**
 * Component handling the price logic for the header in mobile view.
 */
export function HeaderPrice({ }: HeaderPriceProps) {
  const isMixAndMatch = useMixAndMatch();
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.progressive
  );
  const framePrice = useSelector(
    (state: any) => state?.mixAndMatch?.framePrice
  );
  const lensPrice = useSelector((state: any) => state?.mixAndMatch?.lensPrice);
  const currentPrices = useCurrentPrices();
  const [prices, setPrices] = useState(null);
  useEffect(() => {
    if (isMixAndMatch && currentStep === 1) {
      setPrices({
        total: {
          price: Number(lensPrice) + parseFloat(framePrice),
          discount: 0,
          insPrice: 0,
        },
      });
    } else {
      setPrices(currentPrices);
    }
  }, [currentPrices, isMixAndMatch, currentStep, lensPrice, framePrice]);

  const priceFrameLabel = useTranslate("price.totalFrameLens.frame");
  const priceTotalLabel = useTranslate("price.totalFrameLens.frameLensTotal");
  const bundleFrameLensLabel = useTranslate("price.bundleFrameLensStartingAt");
  const totalLabel = useTranslate("price.totalFrameLens.total");
  const lensBundleStep = workflow?.stepChain?.find(s => s.key === "LensBundle")?.progressive;
  const treatmentsFamilyStep = workflow?.stepChain?.find(s => s.key === "TreatmentsFamily")?.progressive;
  const showFramePlusLensesPrice = useShowFramePlusLensesPrice();
  const bundlesEnabled = useBundlesPrices();
  const currentBrand = useCurrentBrand();
  return (
    <div id={"HeaderPrice__container"} className={`HeaderPrice__container ${currentBrand === "rayban" && currentStep === workflow?.stepChain?.find((s) => s.key === "ProtectionPlan")?.progressive
      ? "HeaderPrice--red"
      : ""
      }`}>
      <div
        id={"HeaderPrice__container__label"}
        className={"HeaderPrice__container__label"}
      >
        {!(isMixAndMatch && currentStep === 1) &&
          (!workflow.hasManualSelectionBeenDone() && !showFramePlusLensesPrice) ? (
          <span
            id={"HeaderPrice__container__label__price"}
            className={"HeaderPrice__container__label__price"}
          >
            <p> {priceFrameLabel}:</p>
          </span>
        ) : (
          <span
            id={"HeaderPrice__container__label__frameLensPrice"}
            className={"HeaderPrice__container__label__frameLensPrice"}
          >
            {showFramePlusLensesPrice && currentStep <= (bundlesEnabled ? lensBundleStep : treatmentsFamilyStep) ? (
              <p style={{ textTransform: "initial" }}>{bundleFrameLensLabel}</p>
            ) : showFramePlusLensesPrice && workflow.isLastStep() ? (
              <p>{totalLabel}:</p>
            ) : (
              <p> {priceTotalLabel}:</p>
            )}
          </span>
        )}
      </div>
      {prices && (
        <BrandComponent
          componentName="StepPrice"
          parameter={{
            price: prices.total.price,
            discount: prices.total.discount ? prices.total.discount : null,
            insPrice: prices.total.insPrice ? prices.total.insPrice : null,
            hideSign: true,
            ignorePriceLabels: true,
            hideInsuranceLoader: true,
          }}
        />
      )}
    </div>
  );
}
