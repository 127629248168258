import React from "react";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { getSimplePrescriptionValuesArray } from "../../utils/prescription";
import { BrandComponent } from "../common/BrandComponent";
import { useCurrentBrand } from "@hooks";
import "./default.module.scss";
export type FormTableProps = {
  cylOD;
  cylOS;
  columns;
  isAddDisabled;
  onSubmit;
  formErrors;
  selectPlaceholder;
  selector;
  formValues;
  samePrescriptionBothEyes;
  highlightedColumnIndex;
  readOnly;
  samePrescriptionFrom;
};

export const FormTable = (props: FormTableProps) => {
  const prismSelectPlaceholder = "-";
  const rightEyeInitials = useTranslate(
    "steps.advancedPrescription.manual.rightEye.initials"
  );
  const leftEyeInitials = useTranslate(
    "steps.advancedPrescription.manual.leftEye.initials"
  );
  const rightEyeName = useTranslate(
    "steps.advancedPrescription.manual.rightEye.name"
  );
  const leftEyeName = useTranslate(
    "steps.advancedPrescription.manual.leftEye.name"
  );

  const noneLabel = useTranslate("steps.advancedPrescription.manual.addPlaceholder");
  const isAxisDisabled = (id: string) => {
    if (id.indexOf("right") >= 0) {
      return (
        props.cylOD === null || props.cylOD === undefined || props.cylOD === 0
      );
    } else {
      return (
        props.cylOS === null || props.cylOS === undefined || props.cylOS === 0
      );
    }
  };

  const disabledAxisLabel = (row: any) => {
    let res = true;
    row.map((el) => {
      if (el.id.indexOf("right") >= 0 || el.id.indexOf("left")) {
        return (res =
          props.cylOD === null ||
          props.cylOD === undefined ||
          props.cylOD === 0);
      }
    });
    return res;
  };

  //prevent resending the form when the user presses the ‘Enter’ key
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  const handleChangeAxisValue = (rowId, add) => {
    if (isAxisDisabled(rowId)) {
      return
    }
    const value = add ? + 1 : -1
    props.formValues[props.selector][rowId].setter(
      parseFloat(props.formValues[props.selector][rowId].value + value)
    );
  }


  const brand = useCurrentBrand();
  console.log('samePrescriptionFrom:', props.samePrescriptionFrom);
  return (
    <div className={`form_table ${props.readOnly ? "readOnly" : ""}`} tabIndex={0}>
      <div style={{ display: "flex", maxWidth: "max-content" }}>
        <div className={"form_leftcol" + (props.highlightedColumnIndex === -1 ? " highlight" : "")}>
          <div
            className={"form_leftlabel"}
            tabIndex={0}
          >
            {rightEyeInitials} {rightEyeName}
          </div>
          <div
            className={"form_leftlabel"}
            tabIndex={0}
          >
            {leftEyeInitials} {leftEyeName}
          </div>
        </div>
        {props.columns.map((col, index) => (
          <div
            key={col.id}
            className={
              " form_col" +
              (col.id === "add" && props.isAddDisabled()
                ? " invisible"
                : "") +
              (col.id === "axis" && disabledAxisLabel(col.rows)
                ? " disabled--text"
                : "") +
              (props.isAddDisabled()
                ? " maxWidthWithoutADD"
                : " maxWidthIsADD") +
              (props.samePrescriptionBothEyes && props.samePrescriptionFrom === 'OD' ? " disableOS" : "") +
              (props.highlightedColumnIndex === index ? " highlight" : "")
            }
          >
            <div className={" form_row"} tabIndex={0}>
              {col.name}
            </div>

            {col.rows.map((row) =>
              props.selector === "prescription" ? (
                <div
                  key={row.id}
                  className={"form_row"}
                >
                  {col.id === "add" && (
                    <div
                      id={row.id}
                      className={
                        "form_select"
                      }
                    >
                      <div
                        className={` form_select_prescription${props.formErrors[row.id] ? "__error" : ""
                          }  form_select_prescription__add`}
                      >
                        <BrandComponent
                          componentName={"PrescriptionSelect"}
                          parameter={{
                            name: row.id,
                            id: row.id,
                            value:
                              props.formValues[props.selector][row.id].value,
                            onChange: (e) => {
                              let valueToSet =
                                col.id === "baseDirectionVert" ||
                                  col.id === "baseDirectionHor"
                                  ? e.currentTarget.value
                                  : e;
                              props.formValues[props.selector][row.id].setter(
                                valueToSet
                              );
                            },
                            hasError:
                              props.formValues[props.selector][row.id].hasError,
                            options: getSimplePrescriptionValuesArray(
                              col.min,
                              col.max,
                              col.res,
                              col.id === "add"
                                ? props.selectPlaceholder
                                : props.selector === "prism"
                                  ? prismSelectPlaceholder
                                  : null,
                              2,
                              col.values
                            ).map((e, key) => {
                              return (
                                <option key={key} value={e.value}>
                                  {e.name}
                                </option>
                              );
                            }),
                          }}
                        />
                        {/* <select
                          tabIndex={0}
                          title={row.id}
                          name={row.id}
                          id={row.id}
                          value={
                            props.formValues[props.selector][row.id].value ||
                            ""
                          }
                          onChange={(e) => {
                            let valueToSet =
                              col.id === "baseDirectionVert" ||
                                col.id === "baseDirectionHor"
                                ? e.currentTarget.value
                                : parseFloat(e.currentTarget.value);
                            props.formValues[props.selector][row.id].setter(
                              valueToSet
                            );
                          }}
                        >
                          {getSimplePrescriptionValuesArray(
                            col.min,
                            col.max,
                            col.res,
                            col.id === "add"
                              ? props.selectPlaceholder
                              : props.selector === "prism"
                                ? prismSelectPlaceholder
                                : null,
                            2,
                            col.values
                          ).map((e, key) => {
                            return (
                              <option key={key} value={e.value}>
                                {e.name}
                              </option>
                            );
                          })}
                        </select> */}
                        {/**TODO Only visible for Costa  */}
                        <div
                          className={
                            " form_select__image"
                          }
                        ></div>
                      </div>
                    </div>
                  )}
                  {col.id !== "axis" && col.id !== "add" && (
                    <div
                      id={row.id}
                      className={
                        " form_select_custom"
                      }
                    >
                      <BrandComponent
                        componentName={"PrescriptionSelect"}
                        parameter={{
                          name: row.id,
                          id: row.id,
                          value:
                            props.formValues[props.selector][row.id].value,
                          onChange: (e) => {
                            let valueToSet =
                              col.id === "baseDirectionVert" ||
                                col.id === "baseDirectionHor"
                                ? e.currentTarget.value
                                : e;
                            props.formValues[props.selector][row.id].setter(
                              valueToSet
                            );
                          },
                          hasError:
                            props.formValues[props.selector][row.id].hasError,
                          options: getSimplePrescriptionValuesArray(
                            col.min,
                            col.max,
                            col.res,
                            col.id === "add"
                              ? props.selectPlaceholder
                              : props.selector === "prism"
                                ? prismSelectPlaceholder
                                : null,
                            2,
                            col.values
                          ).map((e, key) => {
                            return (
                              <option key={key} value={e.value}>
                                {e.name}
                              </option>
                            );
                          }),
                        }}
                      />
                    </div>
                  )}
                  {col.id === "axis" && (
                    <div
                      id={row.id}
                      className={
                        " form_select"
                      }
                    >
                      <div
                        className={` form_select_prescription${isAxisDisabled(row.id) ? "__disabled" : ""
                          }${props.formErrors[row.id] ? "__error" : ""}`}
                      >
                        {/* <span key={props.formValues[props.selector][row.id].value}> */}
                        <input
                          type="number"
                          min="0"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          id={row.id}
                          name={row.id}
                          title={row.id}
                          value={
                            props.formValues[props.selector][row.id].value ||
                            ""
                          }
                          onChange={(e) => {
                            props.formValues[props.selector][row.id].setter(
                              parseFloat(e.currentTarget.value)
                            );
                            /* if (
                          e.relatedTarget &&
                          e.relatedTarget["id"] === "submitButtonId"
                        ) {
                          props.onSubmit(e);
                        } */
                          }}
                          onKeyDown={handleKeyDown}
                          placeholder={brand === "default" || "costa" ? noneLabel : props.formErrors[row.id] ? "-" : ""}
                          disabled={isAxisDisabled(row.id)}
                        />
                        {/* </span> */}
                        <div className="arrows">
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleChangeAxisValue(row.id, true)
                            }}
                            className="add">
                          </button>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              handleChangeAxisValue(row.id, false)
                            }}
                            className="subtract">
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div
                  key={row.id}
                  className={" form_row"}
                >
                  <div
                    id={row.id}
                    className={
                      " form_select"
                    }
                  >
                    <div
                      className={` form_select_prescription${props.formErrors[row.id] ? "__error" : ""
                        }  form_select_prescription__add`}
                    > <BrandComponent
                        componentName={"PrescriptionSelect"}
                        parameter={{
                          name: row.id,
                          id: row.id,
                          value:
                            props.formValues[props.selector][row.id].value,
                          onChange: (e) => {
                            let valueToSet =
                              col.id === "baseDirectionVert" ||
                                col.id === "baseDirectionHor"
                                ? e
                                : e;
                            props.formValues[props.selector][row.id].setter(
                              valueToSet
                            );
                          },
                          hasError:
                            props.formValues[props.selector][row.id].hasError,
                          options: getSimplePrescriptionValuesArray(
                            col.min,
                            col.max,
                            col.res,
                            col.id === "add"
                              ? props.selectPlaceholder
                              : props.selector === "prism"
                                ? prismSelectPlaceholder
                                : null,
                            2,
                            col.values
                          ).map((e, key) => {
                            return (
                              <option key={key} value={e.value}>
                                {e.name}
                              </option>
                            );
                          }),
                        }}
                      />
                      {/* <select
                        tabIndex={0}
                        name={row.id}
                        title={row.id}
                        id={row.id}
                        value={
                          props.formValues[props.selector][row.id].value || ""
                        }
                        onChange={(e) => {
                          let valueToSet =
                            col.id === "baseDirectionVert" ||
                              col.id === "baseDirectionHor"
                              ? e.currentTarget.value
                              : parseFloat(e.currentTarget.value);
                          props.formValues[props.selector][row.id].setter(
                            valueToSet
                          );
                        }}
                      >
                        {getSimplePrescriptionValuesArray(
                          col.min,
                          col.max,
                          col.res,
                          col.id === "add"
                            ? props.selectPlaceholder
                            : props.selector === "prism"
                              ? prismSelectPlaceholder
                              : null,
                          2,
                          col.values
                        ).map((e, key) => {
                          return (
                            <option key={key} value={e.value}>
                              {e.name}
                            </option>
                          );
                        })}
                      </select> */}
                    </div>
                  </div>
                </div>
              )
            )}
          </div>
        ))}
      </div>
    </div>
  );
};