import React, { useState, useEffect, useRef } from "react";
import "./default.module.scss";
import { BrandComponent } from "..//common/BrandComponent";
import parse from "html-react-parser";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import useDelegatingLearnMore from "../../configurator/hooks/useDelegatingLearnMore";
import { useSelector } from "react-redux";
import { useCurrentBrand } from "@hooks";
export interface StepTitleProps {
  title: string;
  titleMobile?: string;
  subtitle?: string;
  subtitleStyle?: any;
  slider?: any;
  pills?: any;
  pillsSubtitle?: string;
  brandCallback?: (prop) => void;
  pillsCallback?: (prop) => void;
  pillSelected?: any;
  showGrayOutBanner?: boolean;
  showStepTitle?: boolean;
  key?: string;
}

/**
 * Component that displays the title and subtitle of a step. It accepts an input
 * of type ```StepTitleProps``` with the following parameters:
 *  - ```title```: the title of the step
 *  - ```titleMobile``` (_optional_): the title for the mobile view of that step
 *  - ```subtitle``` (_optional_): the subtitle of the step
 */
export function StepTitle({
  title,
  titleMobile,
  subtitle,
  subtitleStyle,
  slider,
  pills,
  pillsSubtitle,
  brandCallback,
  pillsCallback,
  pillSelected,
  showGrayOutBanner,
  showStepTitle,
  key,
}: StepTitleProps) {
  const buttonColorChangeAndCallback = (prop) => {
    brandCallback(prop);
  };
  const pillsColorChangeAndCallback = (prop) => {
    pillsCallback(prop);
  };
  const delegatingLearnMoreConfig = useSelector(
    (state: any) => state.config.layoutSettings?.delegatingLearnMore
  );
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );

  const learnMoreLabel = useTranslate(`steps.${currentStep?.toLowerCase()}.contentIdentifier`);

  const brand = useCurrentBrand();

  return (
    <div
      className={"StepTitle__container"}
      style={showStepTitle === false ? { display: "none" } : { padding: subtitle && learnMoreLabel !== `steps.${currentStep.toLowerCase()}.contentIdentifier` ? "10px 0px 8px" : "" }}
    >
      <div className={"StepTitle__container__title"}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
          }
        }}
      >
        {title}
      </div>

      <div className={"StepTitle__container__titleMobile"}>
        {titleMobile ? titleMobile : title}
      </div>

      {subtitle && (
        <div
          className={"StepTitle__container__subtitle"}
          style={learnMoreLabel !== `steps.${currentStep.toLowerCase()}.contentIdentifier` ? { marginBottom: "0px" } : subtitleStyle}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      )}
      {/* {learnMoreButton !== "" &&
        learnMoreButton !== null &&
        learnMoreButton !== undefined && (
          <div className={"StepTitle__container__learnMoreButton"}>
            {learnMoreButton}
          </div>
        )} */}
      {showGrayOutBanner && (
        <div
          className={
            "StepTitle__container__grayOutWrapper" +
            (subtitle ? " subtitle" : "") +
            (pills || slider ? " pills" : "")
          }
        >
          <BrandComponent componentName="GrayOutBanner" />
        </div>
      )}

      {
        //Brands
        slider && (
          <BrandComponent
            componentName="optionsSlider"
            parameter={{
              slider: slider,
              callback: buttonColorChangeAndCallback,
            }}
          />
        )
      }
      {
        //Design types
        pills && (
          <>
            <BrandComponent
              componentName="filterPills"
              parameter={{
                pills: pills,
                callback: pillsColorChangeAndCallback,
                selected: pillSelected,
              }}
            />
          </>
        )
      }
      {pillsSubtitle && (
        <div className={"StepTitle__container__pillsSubtitle"}>
          {parse(pillsSubtitle)}
        </div>
      )}
    </div>
  );
}
