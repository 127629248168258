import React, { ReactNode, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modalsActions } from "../../redux/slices/modals";
import HtmlParser from "html-react-parser";
import { isMobile } from "react-device-detect";
import { useTargetDigitalOptometry } from "@hooks";
import "./default.module.scss";

interface PrescriptionModalOpt { }

export function PrescriptionModal({ }: PrescriptionModalOpt) {
  const showInfoModal = useSelector(
    (state: any) => state.modals?.showPrescriptionModalInfo
  );
  const targetDigitalOptometry = useTargetDigitalOptometry();
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.params?.[0]
  );
  const showPDModal = useSelector(
    (state: any) => state.modals?.showPrescriptionModalPD
  );
  const configActionLoadLearnMore = useSelector(
    (state: any) => state.config?.actionsModule?.loadLearnMoreContent
  );
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );

  const reduxDispatch = useDispatch();

  const [infoModalData, setInfoModalData] = useState(null);
  const [PDModalData, setPDModalData] = useState(null);

  const contentIdsInfoModal = [
    "D_LensPanel_HowToReadPrescription",
    "M_LensPanel_HowToReadPrescription",
  ];
  const contentIdsPDModal = [
    "D_LensPanel_PupillaryDistance",
    "M_LensPanel_PupillaryDistance",
  ];

  useEffect(() => {
    if (showInfoModal && configActionLoadLearnMore) {
      if (typeof configActionLoadLearnMore === "function") {
        let promises: Promise<string>[] = contentIdsInfoModal.map((ids) =>
          configActionLoadLearnMore(ids)
        );
        Promise.all(promises).then((htmls: string[]) => {
          if (Array.isArray(htmls)) {
            setInfoModalData({
              desktop: htmls[0],
              mobile: htmls[1],
            });
          }
        });
      }
    } else if (!showInfoModal) {
      setInfoModalData(null);
    }
    if (showPDModal && configActionLoadLearnMore) {
      if (typeof configActionLoadLearnMore === "function") {
        let promises: Promise<string>[] = contentIdsPDModal.map((ids) =>
          configActionLoadLearnMore(ids)
        );
        Promise.all(promises).then((htmls: string[]) => {
          if (Array.isArray(htmls)) {
            setPDModalData({
              desktop: htmls[0],
              mobile: htmls[1],
            });
          }
        });
      }
    } else if (!showPDModal) {
      setPDModalData(null);
    }
  }, [showInfoModal, showPDModal, configActionLoadLearnMore]);

  const checkTargetDigitalOptometry = () => {
    if (targetDigitalOptometry) {
      if (targetDigitalOptometry.includes(currentStep.value)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const closeModal = () => {
    if (showInfoModal) {
      reduxDispatch(modalsActions.setShowPrescriptionModalInfo(false));
    } else {
      reduxDispatch(
        modalsActions.setShowPrescriptionModalPD({
          show: false,
          modal:
            enableDigitalOptometry && checkTargetDigitalOptometry()
              ? enableDigitalOptometry
              : null,
        })
      );
    }
  };

  const videoEventListener = () => {
    let videoPlayButtonClasses = document.getElementsByClassName(
      "PrescriptionModal__container__video__playButton"
    );
    let videoPlayButton = null;
    if (videoPlayButtonClasses.length) {
      isMobile && videoPlayButtonClasses[1]
        ? (videoPlayButton = videoPlayButtonClasses[1])
        : (videoPlayButton = videoPlayButtonClasses[0]);
    }
    videoPlayButton?.classList.remove("invisible");
  };

  useEffect(() => {
    let modalBody = Array.from(
      document.getElementsByClassName("PrescriptionModal__showPDModalBody")
    ) || Array.from(
      document.getElementsByClassName("PrescriptionModal__container__infoModalBody"));

    if (modalBody.length > 0 && (infoModalData || PDModalData)) {
      if (modalBody.length > 0 && (infoModalData || PDModalData)) {
        modalBody.forEach((element) => {
          element.setAttribute("tabIndex", "0");

          Array.from(element.children).forEach((child, index) => {
            if (child.innerHTML) {
              child.setAttribute("tabIndex", "0");

              if (index === 0) {
                child.focus();
              }
            }
          });
        });
      }
    }

  }, [PDModalData, infoModalData]);


  useEffect(() => {
    if (PDModalData && PDModalData.desktop) {
      const prescriptionModalContainer = document.getElementsByClassName("PrescriptionModal__container");

      if (prescriptionModalContainer.length > 0) {
        const modalElement = prescriptionModalContainer[0] as HTMLElement;

        if (modalElement) {
          modalElement.setAttribute("tabIndex", "0");
          modalElement.focus();
        }

        const focusableElements = modalElement.querySelectorAll('button, a, input, [tabindex="0"]');

        if (focusableElements.length > 0) {
          let firstElement = focusableElements[0] as HTMLElement;
          let lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

          const handleTabKey = (event: KeyboardEvent) => {
            if (event.key === "Tab") {
              if (event.shiftKey) {
                if (document.activeElement === firstElement) {
                  event.preventDefault();
                  lastElement.focus();
                }
              } else {
                if (document.activeElement === lastElement) {
                  event.preventDefault();
                  firstElement.focus();
                }
              }
            }
          };
          const enforceFocus = (e: FocusEvent) => {
            if (!modalElement.contains(e.target as Node)) {
              firstElement.focus();
            }
          };

          document.addEventListener("keydown", handleTabKey);
          document.addEventListener("focusin", enforceFocus);

          return () => {
            document.removeEventListener("keydown", handleTabKey);
            document.removeEventListener("focusin", enforceFocus);
          };
        }
      }
    }
  }, [PDModalData]);

  useEffect(() => {
    let closeIcons = Array.from(
      document.getElementsByClassName("PrescriptionModal__container__close")
    );
    closeIcons.map((closeIcon) => {
      if (infoModalData || PDModalData) {
        closeIcon?.setAttribute("data-analytics_available_call", "0");
        closeIcon?.addEventListener("click", closeModal);
        closeIcon?.setAttribute("tabIndex", "0");
        closeIcon?.setAttribute("aria-label", "Close button");
      }
    });

    let videoPlayButtonClasses = document.getElementsByClassName(
      "PrescriptionModal__container__video__playButton"
    );
    let videoPlayButton = null;
    if (videoPlayButtonClasses.length) {
      isMobile && videoPlayButtonClasses[1]
        ? (videoPlayButton = videoPlayButtonClasses[1])
        : (videoPlayButton = videoPlayButtonClasses[0]);
    }

    let video = isMobile
      ? document
        .querySelector(".PrescriptionModal__mobile")
        ?.getElementsByTagName("video")?.[0]
      : document
        .querySelector(".PrescriptionModal__desktop")
        ?.getElementsByTagName("video")?.[0];

    if (!video) {
      return;
    }
    if (videoPlayButton) {
      videoPlayButton.onclick = () => {
        video?.play();
        videoPlayButton.classList.add("invisible");
      };
      video?.addEventListener("ended", videoEventListener);
    }

    return () => {
      closeIcons.map((closeIcon) => {
        closeIcon?.removeEventListener("click", closeModal);
      });
      video?.removeEventListener("ended", videoEventListener);
    };
  }, [infoModalData, PDModalData]);

  useEffect(() => {
    const titleElement = document.querySelector(".PrescriptionModal__container__title");
    if (titleElement) {
      titleElement.setAttribute("tabIndex", "0");
    }
  }, [PDModalData, infoModalData]);


  return (
    <>
      {((infoModalData && (infoModalData.desktop || infoModalData.mobile)) ||
        (PDModalData && (PDModalData.desktop || PDModalData.mobile))) && (
          <>
            <div className="PrescriptionModal__desktop">
              <div
                className="PrescriptionModal__overlay"
                data-analytics_available_call="0"
              />
              {infoModalData &&
                infoModalData.desktop &&
                HtmlParser(infoModalData.desktop)}
              {PDModalData &&
                PDModalData.desktop &&
                HtmlParser(PDModalData.desktop)}

            </div>
            <div className="PrescriptionModal__mobile">
              <div
                className="PrescriptionModal__overlay"
                data-analytics_available_call="0"
              />
              {infoModalData &&
                infoModalData.mobile &&
                HtmlParser(infoModalData.mobile)}
              {PDModalData &&
                PDModalData.mobile &&
                HtmlParser(PDModalData.mobile)}
            </div>
          </>
        )}
    </>
  );
}
