import React, { useCallback, useEffect, useState } from "react";
import { BrandComponent } from "../../../components/common/BrandComponent";
import "./default.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useDynamicPackages } from "@hooks";
import { useTypes } from "../../hooks";
import { workflowActions } from "../../../redux/slices/workflow";
import { useTranslation } from "../../translations/TranslationHooks";

export function MixAndMatch(props) {
  const getDynamicPackages = useDynamicPackages();
  //const { onSelect } = useTypes();
  const dispatch = useDispatch();
  const translate = useTranslation();

  useEffect(() => {
    document.documentElement.classList.add("mixAndMatch")
    dispatch(workflowActions.enablePreselect(false));
    dispatch(workflowActions.setLoadingStep(false));
    return () => {
      document.documentElement.classList.remove("mixAndMatch")
    }
  }, []);

  return (
    <BrandComponent
      componentName="StepContainer"
      parameter={{
        title: translate("steps.mixAndMatch.title"),
      }}
    >
      {/* <BrandComponent
        componentName="SkipContainer"
        parameter={{
          stepName: "MixAndMatch",
          parameterPreselection: ["type"],
          onPreselect: (selected, skipped: boolean) => {
            // if (selected.type === "Non_prescription") {
            //   dispatch(workflowActions.enablePreselect(false));
            //   dispatch(workflowActions.setLoadingStep(false));

            // } else {
            //   onSelect(selected.type, skipped, true);
            // }
            onSelect(selected.type, skipped, true);
          },
          ignoreSkip: false,
        }}
      > */}
      <BrandComponent
        key={"FrameSection"}
        componentName="FrameSection"
        parameter={{
          getPackages: getDynamicPackages,
          onNextFocus: () => {
            const togglePrescriptionElement = document.getElementsByClassName('TogglePrescription__container')
            if (togglePrescriptionElement) {
              togglePrescriptionElement.focus();
            }
          }
        }}
      />
      <div className="divider"></div>
      <BrandComponent key={"LensesSection"} componentName="LensesSection" />
    </BrandComponent>
    // </BrandComponent>
  );
}
