import { groupBy } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IncludedTreatment } from "../../components/IncludedBox/component";
import { StepCardProps } from "../../components/StepCard/component";
import { useTranslation } from "../translations/TranslationHooks";
import { usePriceStepItem } from "./usePriceStepItem";
import { getStepBadgeType } from "../ConfigLoader";

export function useAddonsMapper() {
  const options = useSelector((state: any) => state.config.addonsOptions);
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  //const configPackages = useSelector((state: any) => state.config?.lensesData?.packages);
  const getPriceStep = usePriceStepItem();
  const translate = useTranslation();

  const [ret, setRet] = useState(null);
  const getOptP = (
    opt: string,
    treatmentName: string,
    currentPackages,
    disabled?: boolean
  ) => {
    //'antiReflective','blueLight','antiScratch','uvProtection'
    let ret: StepCardProps & { filter: any } = {
      id: opt + "$" + treatmentName,
      title: treatmentName,
      filter: null,
    };
    switch (opt) {
      case "antiScratch":
        ret.title = translate(
          "steps.treatments.extraTreatments." + opt + ".title"
        );
        ret.imageUrl = translate(
          "steps.treatments.extraTreatments." + opt + ".imageFileName"
        );
        ret.filter = { antiScratch: true };
        break;
      case "uvProtection":
        ret.title = translate(
          "steps.treatments.extraTreatments." + opt + ".title"
        );
        ret.imageUrl = translate(
          "steps.treatments.extraTreatments." + opt + ".imageFileName"
        );
        ret.filter = { uvProtection: true };
        break;
      case "blueLight":
        ret.title =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].title
            : "";
        ret.description =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].description
            : "";
        ret.imageUrl =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].imageFileName
            : "";
        ret.filter = { blueLight: true };
        break;
      case "antiReflective":
        ret.title =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].title
            : "";
        ret.description =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].description
            : "";
        ret.imageUrl =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].imageFileName
            : "";
        ret.textBadge =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].badge
            : null;
        ret.badge =
          configContent[opt] && configContent[opt][treatmentName]
            ? getStepBadgeType(configContent[opt][treatmentName].badge)
            : null;
        ret.filter = { antiReflective: treatmentName };
        break;
      case "transition":
        ret.title =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].title
            : "";
        ret.description =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].description
            : "";
        ret.imageUrl =
          configContent[opt] && configContent[opt][treatmentName]
            ? configContent[opt][treatmentName].imageFileName
            : "";
        ret.filter = { transition: treatmentName };
        break;
    }

    if (!ret.imageUrl) {
      //put default image
      ret.imageUrl = "proceed-icon";
    }

    if (disabled) {
      ret.disabled = true;
    }

    if (!disabled) {
      const priceObj = getPriceStep(ret.filter, currentPackages);
      if (priceObj) {
        ret.price = priceObj.price;
        ret.discount = priceObj.discount;
        ret.insPrice = priceObj.insPrice;
      }
    }
    return ret;
  };

  const getTreatments = (
    currentPackages: { validRXPackages: any[]; invalidRXPackages: any[] },
    empty?: boolean
  ) => {
    let treatments: StepCardProps[] = [];
    let included: IncludedTreatment[] = [];

    if (!currentPackages) {
      return { included, treatments };
    }

    options.forEach((opt: string) => {
      const packageWithTreatments = !empty
        ? currentPackages.validRXPackages.filter((c) => c.lensPackage[opt])
        : currentPackages.validRXPackages;
      const greyOutPackageWithTreatments = !empty
        ? currentPackages.invalidRXPackages.filter((c) => c.lensPackage[opt])
        : currentPackages.invalidRXPackages;

      const treatmentsOfPackets = groupBy(
        packageWithTreatments,
        "lensPackage." + opt
      );
      const greyOutTreatmentsOfPackets = groupBy(
        greyOutPackageWithTreatments,
        "lensPackage." + opt
      );

      if (treatmentsOfPackets) {
        const availableValue = Object.keys(treatmentsOfPackets);
        const greyOutAvailableValue = greyOutTreatmentsOfPackets
          ? Object.keys(greyOutTreatmentsOfPackets).filter(
            (top) => !availableValue.find((v) => v === top)
          )
          : [];

        const allAvailableValues = [
          ...availableValue,
          ...greyOutAvailableValue,
        ];

        if (allAvailableValues.includes("true" || "false")) {
          if (allAvailableValues.length <= 0) {
            //not found
          } else if (allAvailableValues.length === 1) {
            //included
            if (availableValue.length === 1) {
              const mapped = getOptP(
                opt,
                allAvailableValues[0],
                currentPackages.validRXPackages
              );
              included.push({
                name: mapped.title,
                id: opt,
                key: mapped.id.split("$")[1],
              });
            }
          }
        } else {
          //avaiable
          treatments = [
            ...treatments,
            ...availableValue
              .filter(
                (treatmentName) =>
                  empty ||
                  (configContent &&
                    configContent[opt] &&
                    configContent[opt][treatmentName])
              )
              .sort((a, b) => {
                if (a == "null") return -1;
                else if (b == "null") return 1;
                else {
                  return (
                    Number(configContent[opt][a]?.sequence) -
                    Number(configContent[opt][b]?.sequence)
                  );
                }
              })
              .map((treatmentName) =>
                getOptP(opt, treatmentName, currentPackages.validRXPackages)
              ),
            ...greyOutAvailableValue
              .filter(
                (treatmentName) =>
                  empty ||
                  (configContent &&
                    configContent[opt] &&
                    configContent[opt][treatmentName])
              )
              .sort((a, b) => {
                if (a == "null") return -1;
                else if (b == "null") return 1;
                else
                  return (
                    configContent[opt][a]?.sequence -
                    configContent[opt][b]?.sequence
                  );
              })
              .map((treatmentName) =>
                getOptP(
                  opt,
                  treatmentName,
                  currentPackages.invalidRXPackages,
                  true
                )
              ),
          ];
        }
      }
    });
    return { included, treatments };
  };
  useEffect(() => {
    setRet(
      () =>
        (
          currenPackages: {
            validRXPackages: any[];
            invalidRXPackages: any[];
          },
          empty?: boolean
        ) =>
          getTreatments(currenPackages, empty)
    );
  }, [options, configContent, translate]);

  return ret;
}
