import React, { FC, ReactElement, useEffect, useRef } from "react";
import { useTranslate } from "../../../configurator/translations/TranslationHooks";
import GenericButton from "../../common/GenericButton";
import { useCurrentBrand, useEnablePrismComment, usePrismPrice } from "@hooks";
import { useEnablePrism } from "@hooks";
import { BrandComponent } from "../../common/BrandComponent";
import utils from "../utils";
import { useSelector } from "react-redux";

type MoreOptionsProps = {
  isMoreOptionsFormOpen;
  toggleMoreOptionsOpen;
  showPrismValuesForm;
  showPrismMissingValuesError;
  comment: string | null;
  setComment: Function;
  onSubmit: Function;
  checkbox: ReactElement;
  errorMessage: ReactElement;
  prescriptionDefaults?: any;
  cylOD?: any;
  cylOS?: any;
  isAddDisabled?: Function;
  formErrors?: any;
  selectPlaceholder?: string;
  formValues: any;
  samePrescriptionBothEyes?: boolean;
};
export const MoreOptions = (props: MoreOptionsProps) => {
  const enablePrismComment = useEnablePrismComment();
  const brand = useCurrentBrand();
  const enablePrism = useEnablePrism();
  const moreOptions = useTranslate(
    "steps.advancedPrescription.manual.moreOptions"
  );
  const addPrismValues = useTranslate(
    "steps.advancedPrescription.manual.addPrismValues"
  );
  const verticalName = useTranslate(
    "steps.advancedPrescription.manual.vertical"
  );
  const baseDirectionName = useTranslate(
    "steps.advancedPrescription.manual.baseDirection"
  );
  const horizontalName = useTranslate(
    "steps.advancedPrescription.manual.horizontal"
  );
  const comments = useTranslate(
    "steps.advancedPrescription.manual.commentsTitle"
  );
  const prismPrice = usePrismPrice();
  const currentLanguage = useSelector(
    (state: any) => state.config?.translation?.language
  );

  const tableRef = useRef(null);

  useEffect(() => {
    if (props.showPrismValuesForm && tableRef.current) {
      tableRef.current.focus();
    }
  }, [props.showPrismValuesForm]);

  return (
    <>
      {enablePrism && !enablePrismComment ? (
        <>
          {!props.isMoreOptionsFormOpen && (
            <div
              className="MoreOptions__checkboxContainer_noMoreOptions"
              style={
                !enablePrismComment && {
                  marginBottom: "0px",
                }
              }
            >
              {enablePrism && (
                <div
                  style={{
                    display: "flex",
                    //width: "145px",
                  }}
                >
                  {props.checkbox}
                  <div className="MoreOptions__checkBoxText"
                  // tabIndex={0}
                  // onKeyDown={(e) => {
                  //   if (e.key === 'Enter' || e.key === ' ') {
                  //     e.preventDefault();
                  //   }
                  // }}
                  >
                    {addPrismValues}
                  </div>
                  {prismPrice !== false && (
                    <div className="MoreOptions__checkBoxPrismPrice">
                      {"+ $" + prismPrice}
                    </div>)}
                </div>
              )}
              <div aria-live="assertive" role="alert" style={{ position: "absolute", width: "1px", height: "1px", overflow: "hidden" }}>
                {props.showPrismValuesForm ? "prism value form expanded" : "prism value form collapsed"}
              </div>
              <div className="MoreOptions__formWrapper" tabIndex={-1} ref={tableRef} aria-expanded={props.showPrismValuesForm}>
                {props.showPrismValuesForm && (
                  <BrandComponent componentName="FormTable" parameter={{
                    columns: utils.getPrismTableColumns(props.prescriptionDefaults, currentLanguage)(
                      verticalName,
                      horizontalName,
                      baseDirectionName
                    ),
                    selector: "prism",
                    cylOD: props.cylOD,
                    cylOS: props.cylOS,
                    isAddDisabled: props.isAddDisabled,
                    onSubmit: props.onSubmit,
                    formErrors: props.formErrors,
                    selectPlaceholder: props.selectPlaceholder,
                    formValues: props.formValues,
                    samePrescriptionBothEyes: props.samePrescriptionBothEyes,
                    ref: tableRef
                  }} />
                )}
                {props.showPrismValuesForm &&
                  props.showPrismMissingValuesError &&
                  props.errorMessage}
              </div>
            </div>
          )}
        </>
      ) : (
        <div className={"MoreOptions__accordionBtnContainer"}>
          <GenericButton
            className={
              props.isMoreOptionsFormOpen
                ? "MoreOptions__accordionBtnContainer__accordionBtnOpen"
                : "MoreOptions__accordionBtnContainer__accordionBtnClosed"
            }
            title="More options"
            id="moreOptionsAccordionButton"
            type="button"
            noArrow={true}
            handleClick={props.toggleMoreOptionsOpen}
          >
            {moreOptions}
          </GenericButton>
        </div>
      )}

      {props.isMoreOptionsFormOpen && (
        <>
          <div aria-live="assertive" role="alert" style={{ position: "absolute", width: "1px", height: "1px", overflow: "hidden" }}>
            {props.showPrismValuesForm ? "prism value form expanded" : "prism value form collapsed"}
          </div>
          <div
            className={
              brand === "default"
                ? "MoreOptions__checkboxContainer_noMoreOptions_default"
                : "MoreOptions__checkboxContainer_noMoreOptions"
            }
            style={!enablePrism ? { display: "none" } : {}}
          >
            {enablePrism && (
              <div>
                {props.checkbox}
                <div
                  className="MoreOptions__checkBoxText"
                  style={{ display: "inline" }}
                >
                  {addPrismValues}
                </div>
                {prismPrice !== false && (
                  <div className="MoreOptions__checkBoxPrismPrice">
                    {"+ $" + prismPrice}
                  </div>)}
              </div>
            )}
          </div>
          {props.showPrismValuesForm && (
            <div className="MoreOptions__formWrapper" tabIndex={-1} ref={tableRef} aria-expanded={props.showPrismValuesForm}>
              <BrandComponent componentName="FormTable" parameter={{
                columns: utils.getPrismTableColumns(props.prescriptionDefaults, currentLanguage)(
                  verticalName,
                  horizontalName,
                  baseDirectionName
                ),
                selector: "prism",
                cylOD: props.cylOD,
                cylOS: props.cylOS,
                isAddDisabled: props.isAddDisabled,
                onSubmit: props.onSubmit,
                formErrors: props.formErrors,
                selectPlaceholder: props.selectPlaceholder,
                formValues: props.formValues,
                ref: tableRef
              }} />
            </div>
          )}
          {props.showPrismValuesForm &&
            props.showPrismMissingValuesError &&
            props.errorMessage}

          {enablePrismComment && (
            <div className="MoreOptions__commentContainer">
              <div
                className={"MoreOptions__commentContainer__comments_title"}
              >
                {comments}
              </div>
              <textarea
                id="comment"
                rows={6}
                name="comment"
                title="comment"
                defaultValue={props.comment}
                onBlur={(e) => {
                  props.setComment(e.target.value);
                  if (
                    e.relatedTarget &&
                    e.relatedTarget["id"] === "submitButtonId"
                  ) {
                    props.onSubmit(e);
                  }
                }}
              ></textarea>
            </div>
          )}
        </>
      )}
    </>
  );
};
