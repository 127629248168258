import React from "react";
type CustomCheckboxProps = {
  value;
  formErrors?;
  setter;
  setFormErrors?;
  disabled?: boolean;
  formErrorName?: string;
  marginTop?;
  showBothPdOption?: Function;
  checkboxLabel?: string;
};
export const CustomCheckbox = (props: CustomCheckboxProps) => {
  const isChecked = props.value
    ? typeof props.value === "boolean"
      ? props.value
      : props.value.value
    : false;

  const toggleCheckbox = () => {
    if (props.disabled) return;
    if (typeof props.value === "boolean") {
      props.setter(!props.value);
    } else {
      props.setter({
        value: !props.value.value,
        ignoreSplitLogic: true,
      });
    }
    if (props.formErrorName && props.formErrors?.[props.formErrorName]) {
      props.setFormErrors?.({
        ...props.formErrors,
        [props.formErrorName]: false,
      });
    }
  };

  const checkboxId = `checkboxLabel-${Math.random().toString(36).slice(2, 11)}`;

  return (
    <>
      <div
        tabIndex={0}
        role="checkbox"
        aria-checked={isChecked}
        aria-disabled={props.disabled}
        aria-labelledby={checkboxId}
        aria-label={props.checkboxLabel}
        className={
          "CustomCheckbox__checkbox" +
          (isChecked ? " checked" : "") +
          (props.formErrorName && props.formErrors[props.formErrorName]
            ? " error"
            : "") +
          (props.marginTop ? " marginTop" : "") +
          (props.disabled ? " disabled" : "")
        }
        onClick={() => {
          if (props.disabled) return;
          if (typeof props.value === "boolean") {
            props.setter(!props.value);
          } else {
            props.setter({
              value: !props.value.value,
              ignoreSplitLogic: true,
            });
          }
          if (props.formErrorName && props.formErrors[props.formErrorName]) {
            props.setFormErrors({
              ...props.formErrors,
              [props.formErrorName]: false,
            });
          }
        }}
        onKeyDown={(e) => {
          if (props.disabled) return;
          if (e.key === "Enter" || e.key === " ") {
            e.preventDefault();
            toggleCheckbox();
          }
        }}
        onKeyPress={() => {
          if (props.disabled) return;
          if (typeof props.value === "boolean") {
            props.setter(!props.value);
          } else {
            props.setter({
              value: !props.value.value,
              ignoreSplitLogic: true,
            });
          }
          if (props.formErrorName && props.formErrors[props.formErrorName]) {
            props.setFormErrors({
              ...props.formErrors,
              [props.formErrorName]: false,
            });
          }
        }}
      ></div>
      <span id={checkboxId} style={{ position: "absolute", width: "1px", height: "1px", overflow: "hidden" }}>
        {props.checkboxLabel}
      </span>
      <div
        aria-live="polite"
        aria-atomic="true"
        style={{ position: "absolute", width: "1px", height: "1px", overflow: "hidden" }}
      >
        {isChecked ? "checked" : "not checked"}
      </div>
    </>
  );
};
