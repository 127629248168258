import React, { useEffect, useState } from "react";
import "./default.module.scss";
import { useSelector } from "react-redux";
import { BrandComponent } from "../common/BrandComponent";
import GenericButton from "../common/GenericButton";
import { workflow } from "../../configurator/models/Workflow";
import { getCorrectAnalyticsStepContainerName } from "../../configurator/ConfigLoader";
import {
  useTranslate,
  useTranslation,
} from "../../configurator/translations/TranslationHooks";
import {
  useCurrentPackages,
  useCurrentPrices,
  useImagery,
  useMixAndMatch,
  useReviewIncludedTreatmentsMapper,
  useShowFramePlusLensesPrice,
} from "@hooks";
import { getStepSelections } from "../../configurator/ConfigLoader";
import { useStepAlias } from "../../configurator/hooks";

interface BottomBarProps { }

/**
 * The component handling the logic of the bottom bar in the desktop view. It uses the
 * ```useCurrentPrices()``` hook to access the current prices for the frame, lens, and total.
 */
export function BottomBar({ }: BottomBarProps) {
  //const configProductImage = useSelector((state: any) => state.config?.baseURLs?.productImage)
  const getAliasStep = useStepAlias();
  const configContent = useSelector(
    (state: any) => state.config?.lensesData?.content
  );
  const selectedType = useSelector(
    (state: any) => state?.mixAndMatch?.selectedType
  );
  const isMixAndMatchStep = useMixAndMatch();
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.progressive
  );
  const currentStepName = useSelector(
    (state: any) => state.workflow?.currentStep?.key
  );
  const framePrice = useSelector(
    (state: any) => state?.mixAndMatch?.framePrice
  );
  const lensPrice = useSelector((state: any) => state?.mixAndMatch?.lensPrice);
  const currentPrices = useCurrentPrices();
  const [prices, setPrices] = useState(null);
  const translation = useTranslation();
  const currentPackages = useCurrentPackages();
  const loadTreatments = useReviewIncludedTreatmentsMapper();
  const addToBagLabel = useTranslate("steps.treatments.addToBag");
  const reviewTitle = useTranslate("steps.review.title");
  const insuranceLegalCopy = useTranslate("insuranceLegalBanner");
  const insuranceSyncedLabel = useTranslate("insuranceSyncedLabel");
  const addInsuranceLabel = useTranslate("insuranceButtonLabel");
  const removeInsuranceLabel = useTranslate("insuranceButtonLabelRemove");
  const insuranceLabelRemove = useTranslate("insuranceLabelRemove");
  const imageryObject = useImagery();
  const configInsurance = useSelector(
    (state: any) => state.config?.insuranceModule
  );
  const insuranceEnabled = useSelector(
    (state: any) => state.insurance?.insuranceEnabled
  );
  const insuranceLoading = useSelector(
    (state: any) => state.insurance?.loading
  );
  const pricingMethodConfig = useSelector(
    (state: any) => state.insurance?.pricingMethodConfig
  );
  const sessionWarranty = useSelector(
    (state: any) => state.insurance?.warranty
  );
  const reviewObjectForCart = useSelector(
    (state: any) => state.workflow?.reviewObjectForCart
  );
  const isLoadingPackages = useSelector(
    (state: any) => state?.mixAndMatch?.loadingPackages
  );

  const [isInsuranceEnabled, setIsInsuranceEnabled] = useState(false);
  const [isInsuranceLoading, setIsInsuranceLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  const lensBundleStep = workflow?.stepChain?.find(
    (s) => s.key === "LensBundle"
  )?.progressive;

  const showFramePlusLensesPrice = useShowFramePlusLensesPrice();

  const isInsurable = () => {
    return !!configInsurance;
  };

  useEffect(() => {
    setIsInsuranceEnabled(insuranceEnabled);
  }, [insuranceEnabled]);

  useEffect(() => {
    setIsInsuranceLoading(insuranceLoading);
  }, [insuranceLoading]);

  useEffect(() => {
    if (isMixAndMatchStep && currentStep === 1) {
      setPrices({
        total: { price: Number(lensPrice) + parseFloat(framePrice) },
      });
    } else {
      setPrices(currentPrices);
    }
  }, [currentPrices, isMixAndMatchStep, currentStep, lensPrice, framePrice]);

  const handleInsuranceClick = () => {
    if (configInsurance) {
      if (!isInsuranceEnabled && configInsurance.openInsurancePanel) {
        configInsurance.openInsurancePanel();
      } else if (
        isInsuranceEnabled &&
        configInsurance.removeInsuranceBenefits
      ) {
        configInsurance.removeInsuranceBenefits();
      }
    }
  };

  const getDataElementIdForInsurance = () => {
    let currentStepName = workflow.currentStep?.key;
    let analyticsStepName =
      getCorrectAnalyticsStepContainerName(currentStepName);
    return "X_X_LensPanel_" + analyticsStepName + "-UseInsurance";
  };
  //  temporary
  const getMixAndMatchSteps = () => {
    let steps = getStepSelections(getAliasStep, true, configContent, true);

    const thereIsTreatmentStep = steps.find((step) => step.key == "treatments");
    const thereIsTreatmentFamilyStep = steps.find(
      (step) => step.key == "treatmentsFamily"
    );
    //filter all treatments if the family is not defined
    steps = steps.filter((step) => {
      return !(
        step.key === "treatments" &&
        thereIsTreatmentStep &&
        thereIsTreatmentFamilyStep &&
        (!configContent.treatmentFamily ||
          Object.keys(configContent.treatmentFamily).length == 0)
      );
    });
    let colorStep = steps.find(
      (t) => t.originalStep == "TransitionColor" && t.price
    );
    if (colorStep) {
      steps = steps.map((s) => {
        if (s.originalStep == "TreatmentsFamily") {
          return {
            ...s,
            price: s.price + colorStep.price,
            discount: s.discount + colorStep.discount,
            insPrice: null,
          };
        } else if (colorStep.originalStep == s.originalStep) {
          let obj = {};
          Object.keys(s).forEach((k) => {
            // obj[k] = s[k];
            if (!["price", "insPrice", "discount"].includes(k)) {
              obj[k] = s[k];
            }
          });
          return obj;
        }
        return s;
      });
    }
    return steps;
  };
  const confirmedTitlesMap = {
    type: useTranslate("steps.type.confirmedTitle"),
    brand: useTranslate("steps.brand.confirmedTitle"),
    advancedPrescription: useTranslate(
      "steps.advancedPrescription.confirmedTitle"
    ),
    treatmentsFamily: useTranslate("steps.treatmentsFamily.confirmedTitle"),
    gvpTreatment: useTranslate("steps.gvpTreatment.confirmedTitle"),
    thickness: useTranslate("steps.thickness.confirmedTitle"),
    treatments: useTranslate("steps.treatments.confirmedTitle"),
    transitionColor: useTranslate("steps.transitionColor.confirmedTitle"),
    lensColor: useTranslate("steps.lensColor.confirmedTitle"),
    color: useTranslate("steps.color.confirmedTitle"),
    addOns: useTranslate("steps.addOns.confirmedTitle"),
  };
  const getReviewObjectForCart = (history: any) => {
    let ret = {};
    history = history.filter(
      (hs: any) => hs?.id?.toLowerCase() !== "protectionplan"
    );
    history.forEach((step, index) => {
      let stepObj = { sequence: index + 1 };
      stepObj["name"] = confirmedTitlesMap[step.id];

      if (step.id !== "addOns" || typeof step.title !== typeof []) {
        stepObj["description"] = step.title;
        if (
          step.strikePrice !== undefined &&
          step.strikePrice !== null &&
          !isNaN(step.strikePrice)
        ) {
          stepObj["listPrice"] = step.strikePrice;
        }
        if (
          step.offerPrice !== undefined &&
          step.offerPrice !== null &&
          !isNaN(step.offerPrice)
        ) {
          stepObj["offerPrice"] = step.offerPrice;
        }
      } else {
        let childrenArray = step.title?.map((selection, index) => {
          let retObj = { sequence: index + 1, name: selection };
          if (index === 0) {
            retObj["listPrice"] = step.strikePrice;
            retObj["offerPrice"] = step.offerPrice;
            if (
              isInsuranceEnabled &&
              currentPrices.lens.insPrice !== undefined &&
              currentPrices.lens.insPrice !== null &&
              !isNaN(currentPrices.lens.insPrice)
            ) {
              retObj["insPrice"] = currentPrices.lens.insPrice;
            }
          }
          return retObj;
        });
        stepObj["children"] = childrenArray;
      }
      ret[step.originalStep] = stepObj;
    });
    return ret;
  };
  const onAddToBag = () => {
    if (isMixAndMatchStep && currentStep === 1) {
      const nonPrescriptionPackages = currentPackages.filter(
        (p) => p.lensPackage.type === "Non_prescription"
      );
      const { included, treatments } = loadTreatments(nonPrescriptionPackages);

      const alreadyIncluded = {
        included: included.reverse(),
        treatments: treatments,
      };
      let mixAndMatchSteps = getMixAndMatchSteps();
      let addOnsStep = mixAndMatchSteps.find(
        (step) => step.key?.toLowerCase() === "addons"
      );
      mixAndMatchSteps = mixAndMatchSteps.filter(
        (step: any) => step.key?.toLowerCase() !== "addons"
      );
      let history = mixAndMatchSteps.map((p) => ({
        id: p.key,
        title: p.title,
        originalStep: p.originalStep,
        onClick: () => { },
        strikePrice: p.price,
        offerPrice: p.price - p.discount,
        insPrice: p.insPrice,
        attributes: p.attributes,
      }));
      if (alreadyIncluded.included.length) {
        if (addOnsStep) {
          let toPush = [];
          alreadyIncluded.treatments.map((x) => {
            toPush.push(translation(x.name));
          });
          alreadyIncluded.included.map((x) => {
            if (alreadyIncluded.treatments.length) {
              alreadyIncluded.treatments.map((y) => {
                if (y.id.indexOf(x.id) === -1) {
                  toPush.push(translation(x.name));
                }
              });
            } else {
              toPush.push(translation(x.name));
            }
          });
          addOnsStep.title = toPush;
          if (
            isNaN(addOnsStep.offerPrice) &&
            (addOnsStep.insPrice == null || addOnsStep.insPrice == undefined)
          ) {
            addOnsStep.offerPrice = 0;
            addOnsStep.insPrice = 0;
          }
          let addonStepReMapped = {
            id: addOnsStep.key,
            title: addOnsStep.title,
            originalStep: addOnsStep.originalStep,
            onClick: () => { },
            strikePrice: addOnsStep.price,
            offerPrice: addOnsStep.price - addOnsStep.discount,
            insPrice: addOnsStep.insPrice,
            attributes: addOnsStep.attributes,
          };
          history.push(addonStepReMapped);
        } else {
          let toPush = [];
          alreadyIncluded.included.map((x) => {
            toPush.push(translation(x.name));
          });
          history.push({
            attributes: undefined,
            id: "addOns",
            offerPrice: 0,
            insPrice: 0,
            originalStep: "AddOns",
            strikePrice: undefined,
            title: toPush,
            hideEdit: true,
          });
        }
      }
      if (!history.find((step: any) => step?.title?.includes("Frame Only"))) {
        let protectionStep = history.find(
          (hs: any) => hs.id === "protectionplan"
        );
        if (protectionStep) {
          history = history.filter(
            (hs: any) => hs.id.toLowerCase() !== "protectionplan"
          );
          history.push(protectionStep);
        }
        window.dispatchEvent(
          new CustomEvent("AddToCartEvent", {
            detail: {
              selectedWarranty:
                sessionWarranty?.id === "asIs" ? null : sessionWarranty,
              reviewObjectForCart: getReviewObjectForCart(history),
              imagery: imageryObject,
            },
          })
        );
        setButtonLoading(true);
      }
    } else {
      console.log("selectedWarranty", sessionWarranty);
      console.log("reviewObjectForCart", reviewObjectForCart);
      console.log("imageryObject", imageryObject);
      window.dispatchEvent(
        new CustomEvent("AddToCartEvent", {
          detail: {
            selectedWarranty:
              sessionWarranty?.id === "asIs" ? null : sessionWarranty,
            reviewObjectForCart: reviewObjectForCart,
            imagery: imageryObject,
          },
        })
      );
      setButtonLoading(true);
    }
  };

  return (
    <div
      className={
        pricingMethodConfig &&
          (pricingMethodConfig === "QUICK" ||
            pricingMethodConfig === "STANDARD_REVIEW")
          ? "BottomBar__legalBanner"
          : showFramePlusLensesPrice &&
            currentStep <= lensBundleStep &&
            currentStepName !== "AdvancedPrescription"
            ? "BottomBar__hide"
            : "BottomBar" +
            ((isMixAndMatchStep && currentStep === 1 && selectedType === "Non_prescription") ||
              (isMixAndMatchStep && currentStep === 1 && workflow.isLastStep())
              ? " mixAndMatch"
              : "")}
    >
      <div
        className={
          "BottomBar__container" +
          (currentStep &&
            currentStep === workflow.getMaxStep(workflow.stepChain).progressive
            ? " BottomBar__container__containerLastPage"
            : "") +
          (!isInsurable() ? " BottomBar__container__noInsurance" : "")
        }
      >
        {isInsurable() && !(isMixAndMatchStep && currentStep === 1) && (
          <div className={"BottomBar__container__left"}>
            {currentStep &&
              currentStep !==
              workflow.getMaxStep(workflow.stepChain).progressive &&
              (isInsuranceLoading || isInsuranceEnabled ? (
                <div className="BottomBar__container__left__wrapper">
                  <div className="BottomBar__container__left__insuranceWrapper">
                    <div
                      className={"BottomBar__container__left__insuranceEnabled"}
                    >
                      {insuranceSyncedLabel}
                    </div>
                    <BrandComponent
                      componentName="InsuranceTooltip"
                      parameter={{ id: "tooltipDesktop" }}
                    />
                  </div>
                  <span
                    className="BottomBar__container__left__removeInsuranceLabel"
                    onClick={handleInsuranceClick}
                  >
                    {insuranceLabelRemove}
                  </span>
                </div>
              ) : (
                <button
                  type="button"
                  className={"BottomBar__container__left__insurance"}
                  onClick={handleInsuranceClick}
                >
                  {addInsuranceLabel}
                </button>
              ))}
            {currentStep &&
              currentStep ===
              workflow.getMaxStep(workflow.stepChain).progressive && (
                <>
                  {isInsuranceEnabled &&
                    !configInsurance.removeInsuranceBenefits ? (
                    <div
                      className={"BottomBar__container__left__insuranceEnabled"}
                    >
                      {insuranceSyncedLabel}
                    </div>
                  ) : (
                    <GenericButton
                      className={
                        "BottomBar__container__left__addInsuranceButton"
                      }
                      title={addToBagLabel}
                      id="continueToReviewButton"
                      type="button"
                      noArrow={true}
                      handleClick={handleInsuranceClick}
                      dataElementId={getDataElementIdForInsurance()}
                      tabIndex={0}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          handleInsuranceClick();
                        }
                      }}
                    >
                      {isInsuranceEnabled
                        ? removeInsuranceLabel
                        : addInsuranceLabel}
                    </GenericButton>
                  )}
                </>
              )}
          </div>
        )}
        <div
          className={
            "BottomBar__container__right" +
            (!isInsurable() ? " BottomBar__container__right__noInsurance" : "")
          }
        >
          {prices && !workflow.isLastStep() && (
            <>
              <BrandComponent
                componentName="BottomBarTotalPrice"
                parameter={{
                  total: prices.total,
                }}
              />
            </>
          )}

          {((currentStep && workflow.isLastStep()) ||
            (isMixAndMatchStep &&
              currentStep === 1 &&
              selectedType === "Non_prescription")) && (
              <GenericButton
                className={`BottomBar__container__right__addToBagButton ${buttonLoading ? "loading" : ""
                  }`}
                title={addToBagLabel}
                id="continueToReviewButton"
                type="button"
                noArrow={true}
                disabled={
                  buttonLoading ||
                  isLoadingPackages ||
                  (isMixAndMatchStep && currentStep === 1 && !lensPrice)
                }
                handleClick={onAddToBag}
                dataElementId={
                  isMixAndMatchStep && currentStep === 1
                    ? "X_X_LensPanel_Mixmatch-AddCart"
                    : "X_X_Prod_AddCart"
                }
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    onAddToBag();
                  }
                }}
              >
                {addToBagLabel}
              </GenericButton>
            )}
          {pricingMethodConfig === "QUICK" ||
            (pricingMethodConfig === "STANDARD_REVIEW" && (
              <div className="BottomBar__container__right__legalBanner">
                {insuranceLegalCopy}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}
