import React, { useEffect, useMemo, useRef, useState } from "react";
import "./default.module.scss";
import { BrandComponent } from "../common/BrandComponent";
import { useDispatch, useSelector } from "react-redux";
import { useConfigContent, useCurrentPrices } from "@hooks";
import NumberFormat from "react-currency-format";
import { configActions } from "../../redux/slices/config";
import { colorActions } from "../../redux/slices/color";
import { mixAndMatchActions } from "../../redux/slices/mixAndMatch";
import { useTranslation } from "../../configurator/translations/TranslationHooks";

export function FrameSection({ getPackages, onNextFocus }) {
  const frameColors = useConfigContent("frameColor");
  const [selectedFrame, setSelectedFrame] = useState(null);
  const translate = useTranslation();

  const framePrice = useSelector(
    (state: any) => state?.mixAndMatch?.framePrice
  );

  // const selectedFrm = useSelector(
  //   (state: any) => state.mixAndMatch?.selectedFrame
  // );

  const frameTotalPrice = useSelector(
    (state: any) => state?.mixAndMatch?.frameTotalPrice
  );

  const currencyFormat = useSelector(
    (state: any) => state.config.currencyFormat
  );
  const loading = useSelector(
    (state: any) => state?.mixAndMatch?.loadingPackages
  );

  const frames = useSelector((state: any) => state.config.data.frames);
  const frame = useSelector((state: any) => state.config.data.frame);
  const bridgeLabel = translate("steps.mixAndMatch.bridgeLabel");
  const reduxDispatch = useDispatch();

  const getFrame = (colorId, callGetPackages?: boolean) => {
    const foundFrameColor =
      frames.length > 0
        ? frames?.find((entry) => entry.color === colorId)
        : frame;
    if (!foundFrameColor) return;
    reduxDispatch(mixAndMatchActions.setSelectedFrame(foundFrameColor));
    reduxDispatch(
      mixAndMatchActions.setFramePrice(Number(foundFrameColor?.offerPrice))
    );
    reduxDispatch(
      mixAndMatchActions.setFrameTotalPrice(Number(foundFrameColor?.listPrice))
    );
    reduxDispatch(configActions.changeFrame(foundFrameColor));
    reduxDispatch(colorActions.reset());
    let frameImgUrl = "";
    if (foundFrameColor?.imageUrl?.includes("bgc")) {
      frameImgUrl = foundFrameColor?.imageUrl.split("bgc")[0] + "bgc=%23ffffff";
    }
    reduxDispatch(colorActions.setFrameImgUrl(frameImgUrl));
    if (callGetPackages) {
      getPackages(foundFrameColor);
    }
  };

  const [tooltipVisible, setTooltipVisible] = useState(false);
  const tabPressCount = useRef(0);


  const getBridgeAndNosePads = () => {
    if (frame && frames) {
      if (frame?.bridgeAndNosepads) {
        if (frames.every((frame: any) => frame.bridgeAndNosepads)) {
          return (
            <div className="FrameSection__container__bridge">
              <p className="FrameSection__container__title" tabIndex={0}>{bridgeLabel}</p>
              <p className="FrameSection__container__bridgeDescription"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                  }
                }}>
                {frame.bridgeAndNosepads.description}
              </p>
              <div className="FrameSection__container__tooltip">
                <div className="FrameSection__container__tooltip__icon"
                  onMouseEnter={() => setTooltipVisible(true)}
                  onMouseLeave={() => setTooltipVisible(false)}
                  tabIndex={0}
                  aria-label={tooltipVisible ? frame.bridgeAndNosepads.tooltip : "more info"}
                  onKeyDown={(e) => {
                    if (e.key === 'Tab') {
                      tabPressCount.current += 1;

                      if (tabPressCount.current === 1) {
                        setTooltipVisible(true);
                      }

                      if (tabPressCount.current > 1) {
                        setTooltipVisible(false);
                        tabPressCount.current = 0;

                        if (onNextFocus) {
                          onNextFocus();
                        }
                      }

                      e.preventDefault();
                    }
                  }}>
                  {/* <span id="tooltip-label" style={{ position: "absolute", width: "1px", height: "1px", overflow: "hidden" }}>More info</span> */}
                  {tooltipVisible &&
                    <div className={`FrameSection__container__tooltip__icon__text ${tooltipVisible ? "visible" : ""
                      }`}>
                      <p style={{ margin: "0px 8px 0px 0px", width: "80%" }}>
                        {frame.bridgeAndNosepads.tooltip}
                      </p>
                      <div className="FrameSection__container__tooltip__icon__text__close" />
                    </div>
                  }
                </div>
              </div>
            </div>
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!selectedFrame) {
      if (frameColors && frameColors.length > 0 && frame) {
        let selectedFrameFromPDP = null;
        if (frames && frames.length > 0) {
          selectedFrameFromPDP = frames?.find(
            (entry) => entry.color === frame.color
          );
        } else {
          selectedFrameFromPDP = frame;
        }
        if (selectedFrameFromPDP) {
          const preselectedFrameColor = frameColors.find(
            (entry) => entry.id === selectedFrameFromPDP?.color
          );
          if (preselectedFrameColor) {
            setSelectedFrame(preselectedFrameColor);
            getFrame(preselectedFrameColor.id, false);
          }
        }
      }
    }
  }, [frame, frames, selectedFrame, frameColors]);

  const renderPrice = (value: number) => {
    return (
      <NumberFormat
        value={value}
        displayType={"text"}
        prefix={currencyFormat.suffix ? "" : currencyFormat.prefix + " "}
        suffix={currencyFormat.suffix}
        decimalSeparator={currencyFormat.decimalSeparator}
        thousandSeparator={currencyFormat.thousandSeparator}
        decimalScale={
          !isNaN(Number(currencyFormat?.decimalScale))
            ? Number(currencyFormat?.decimalScale)
            : 2
        }
        fixedDecimalScale={true}
        renderText={(value: string) => <>{value}</>}
      />
    );
  };

  const onSelectFrameCard = (color) => {
    setSelectedFrame(color);
    getFrame(color.id, true);
  };

  const frameColorsFilteredByTitle = useMemo(() => {
    const uniqueTitles = {};
    return frameColors.filter((color) => {
      if (!uniqueTitles[color?.title]) {
        uniqueTitles[color?.title] = true;
        return true;
      }
      return false;
    });
  }, [frameColors]);

  return (
    <div className="FrameSection__container">
      <span className="FrameSection__container__title">
        {translate("steps.mixAndMatch.frame")}:
        {frameTotalPrice > framePrice && (
          <span className="FrameSection__container__price__strikePrice">
            {renderPrice(frameTotalPrice)}
          </span>
        )}
        <span className="FrameSection__container__price">
          {renderPrice(framePrice)}
        </span>
      </span>
      <BrandComponent componentName="Carousel">
        {frameColorsFilteredByTitle?.map((color, i) => (
          <BrandComponent
            key={color.id}
            componentName="FrameCard"
            parameter={{
              ...color,
              selected: color.title === selectedFrame?.title,
              onSelectFrameCard: () => onSelectFrameCard(color),
              loading: loading,
            }}
          />
        ))}
      </BrandComponent>
      {getBridgeAndNosePads()}
    </div>
  );
}
