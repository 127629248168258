import React, { useRef } from 'react'
import "./default.module.scss"
import { useTranslation } from '../../configurator/translations/TranslationHooks'
import { useDispatch } from 'react-redux'
import { mixAndMatchActions } from '../../redux/slices/mixAndMatch'

export function TogglePrescription(props) {
  const translate = useTranslation()
  const nonPrescription = translate("steps.mixAndMatch.nonPrescription")
  const prescription = translate("steps.mixAndMatch.prescription")

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const selectedType = e.target.checked ? 'Prescription' : 'Non_prescription';
    dispatch(mixAndMatchActions.setSelectedType(selectedType));
    props.toggle(e.target.checked);
  };

  const nextFocusableRef = useRef(null);
  const handleNextFocus = () => {
    if (props.onNextFocus) {
      props.onNextFocus();
    }
  };

  return (
    <div className='TogglePrescription__container' tabIndex={0} onNextFocus={handleNextFocus}>
      <input type="checkbox" role="switch" id="prescription" onChange={handleChange} checked={props.checked} />
      <label htmlFor="prescription"
        ref={nextFocusableRef}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
          }
        }}>
        <span
          tabIndex={0}
          role="button"
          aria-pressed={!props.checked}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              if (props.checked) handleChange({ target: { checked: false } });
            }
          }}
          data-translation="steps.mixAndMatch.nonPrescription"
          className='non-prescription'>
          {nonPrescription}
        </span>
        <span
          tabIndex={0}
          role="button"
          aria-pressed={props.checked}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              if (!props.checked) handleChange({ target: { checked: true } });
            }
          }}
          data-translation="steps.mixAndMatch.prescription"
          className='prescription'>
          {prescription}
        </span>
      </label>
    </div>
  )
}
