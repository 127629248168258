import React, {
  FC,
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { modalsActions } from "../../redux/slices/modals";
import { getSimplePrescriptionValuesArray } from "../../utils/prescription";
import { BrandComponent } from "../common/BrandComponent";
import { WarningBanner } from "../WarningBanner/component";
import { isMobile } from "react-device-detect";
import { useTargetDigitalOptometry } from "../../configurator/hooks/index";

import "./default.module.scss";

type IShowBothPdOptions = {
  value: boolean;
  ignoreSplitLogic: boolean;
};

type Props = {
  PD1: number | null | undefined;
  PD2: number | null | undefined;
  showBothPdOptions: IShowBothPdOptions;
  showPDWarning: boolean;
  onPD1Change: (v) => void;
  onPD2Change: (v) => void;
  setShowBothPdOptions: Function;
  pdValueNotSelectedError: boolean;
  CustomCheckbox: FC<{ value: IShowBothPdOptions; setter: Function, checkboxLabel }>;
  brand: string;
  readOnly: boolean;
};

export const PupillaryDistance = ({
  showBothPdOptions,
  CustomCheckbox,
  pdValueNotSelectedError,
  PD1,
  PD2,
  onPD1Change,
  onPD2Change,
  setShowBothPdOptions,
  showPDWarning,
  brand,
  readOnly
}: Props) => {
  const pupillaryDistanceTitle = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistance"
  );
  const pdDigitalOptometryTitle = useTranslate(
    "steps.advancedPrescription.manual.pdDigitalOptometryTitle"
  );
  const whatIsIt = useTranslate("steps.advancedPrescription.manual.whatIsIt");
  const pupillaryDistanceMisurePDAction = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceMisurePDAction"
  );
  const pdSubtitle = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceSubtitle"
  );
  const iHaveTwoPd = useTranslate(
    "steps.advancedPrescription.manual.iHaveTwoPd"
  );

  const pupillaryDistanceWarningValue = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceWarningValue"
  );
  const pdRight = useTranslate("steps.advancedPrescription.manual.pdRight");
  const pdLeft = useTranslate("steps.advancedPrescription.manual.pdLeft");

  const prescriptionDefaults = useSelector(
    (state: any) => state.config.prescriptionDefaults
  );
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.params?.[0]
  );
  const enableDigitalOptometry = useSelector(
    (state: any) => state.config.layoutSettings?.enableDigitalOptometry
  );
  const targetDigitalOptometry = useTargetDigitalOptometry();
  const pdValueWarning = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarning"
  );
  const pdValueWarningMobile = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarningMobile"
  );
  const pdValueWarning2 = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarning2"
  );
  const pdValueWarningDigitalOptometrySmaller = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarningDigitalOptometrySmaller"
  );
  const pdValueWarningDigitalOptometryLarger = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarningDigitalOptometryLarger"
  );
  const pdValueWarningSmaller = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarningSmaller"
  );
  const pdValueWarningLarger = useTranslate(
    "steps.advancedPrescription.manual.alerts.pdValueWarningLarger"
  );
  const howToRead = useTranslate(
    "steps.advancedPrescription.manual.pupillaryDistanceMisurePDActionHowToRead"
  );
  const reduxDispatch = useDispatch();

  const checkTargetDigitalOptometry = () => {
    if (targetDigitalOptometry) {
      if (
        targetDigitalOptometry.includes(currentStep?.value) ||
        targetDigitalOptometry === "[]" ||
        targetDigitalOptometry.length === 0 ||
        !targetDigitalOptometry
      ) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleMeasurePDClick = () => {
    try {
      //@ts-ignore
      window.tealium_data2track.push({
        'id': 'Click',
        'data_element_id': 'LensPanel_AdvancedPrescription-MeasurePD',
        'data_description': 'MeasureYourPD'
      });
    } catch (error) {
      console.log("error with tealium.push: " + error);
    }
    showPrescriptionModalPD(true)
  }

  const showPrescriptionModalPD = useCallback(
    (show: boolean) =>
      reduxDispatch(
        modalsActions.setShowPrescriptionModalPD({
          show,
          modal:
            enableDigitalOptometry && checkTargetDigitalOptometry()
              ? enableDigitalOptometry
              : null,
        })
      ),
    []
  );
  const closeDoublePD = () => {
    setShowBothPdOptions({
      ...showBothPdOptions,
      value: !showBothPdOptions.value,
    });
    onPD1Change(!enableDigitalOptometry ? 63 : null);
    onPD2Change(null);
  };

  const tableRef = useRef(null);

  const handleCheckboxChange = (value) => {
    setShowBothPdOptions(value);
    setTimeout(() => {
      if (tableRef.current) {
        tableRef.current.focus();
      }
    }, 0);
  };

  const PDWarningText = useMemo(() => {
    const smaller = (!showBothPdOptions.value && (PD1 && PD1 < 55)) || (showBothPdOptions.value && (PD1 && PD1 <= 27 && PD2 && PD2 <= 27))
    const Larger = (!showBothPdOptions.value && (PD1 && PD1 >= 76)) || (showBothPdOptions.value && (PD1 && PD1 >= 38 && PD2 && PD2 >= 38))
    if (enableDigitalOptometry && checkTargetDigitalOptometry()) {
      if (smaller) {
        return pdValueWarningDigitalOptometrySmaller
      } else if (Larger) {
        return pdValueWarningDigitalOptometryLarger
      }
    } else {
      if (smaller) {
        return pdValueWarningSmaller
      } else if (Larger) {
        return pdValueWarningLarger
      }
      return ""
    }
  }, [PD1, PD2, enableDigitalOptometry, checkTargetDigitalOptometry, showBothPdOptions.value])

  return (

    <div className="PupillaryDistance">
      {!readOnly && (<div className={"PupillaryDistance__titleContainer"}>

        {brand === "opsm" && enableDigitalOptometry && checkTargetDigitalOptometry() && !readOnly && (
          <>
            <div className={"PupillaryDistance__titleContainer__infoIcon"}>
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17" fill="none">
                <g clip-path="url(#clip0_1239_3850)">
                  <path d="M8 0.5C3.58867 0.5 0 4.08867 0 8.5C0 12.9113 3.58867 16.5 8 16.5C12.4113 16.5 16 12.9113 16 8.5C16 4.08867 12.4113 0.5 8 0.5ZM8 15.1667C4.324 15.1667 1.33333 12.176 1.33333 8.5C1.33333 4.824 4.324 1.83333 8 1.83333C11.676 1.83333 14.6667 4.824 14.6667 8.5C14.6667 12.176 11.676 15.1667 8 15.1667Z" fill="#2D2A26" />
                  <path d="M8.66667 7.16667H7.33333V12.5H8.66667V7.16667Z" fill="#2D2A26" />
                  <path d="M8.66667 4.5H7.33333V5.83333H8.66667V4.5Z" fill="#2D2A26" />
                </g>
                <defs>
                  <clipPath id="clip0_1239_3850">
                    <rect width="16" height="16" fill="white" transform="translate(0 0.5)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className={"PupillaryDistance__titleContainer__digitalOptometryTitle"}>
              {pdDigitalOptometryTitle}
            </div>
          </>
        )}

        {(!enableDigitalOptometry ||
          (enableDigitalOptometry && !checkTargetDigitalOptometry())) &&
          (
            <div
              tabIndex={0}
              className={"PupillaryDistance__titleContainer__link"}
              onClick={() => showPrescriptionModalPD(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  showPrescriptionModalPD(true);
                }
                if (e.key == "Escape") {
                  showPrescriptionModalPD(false);
                }
              }}
            >
              {whatIsIt}
            </div>
          )}
      </div>)}
      {enableDigitalOptometry && checkTargetDigitalOptometry() && !readOnly && (
        <div
          data-content={pupillaryDistanceMisurePDAction}
          className="PupillaryDistance__subtitleContainer"
          onClick={() => handleMeasurePDClick()}
          dangerouslySetInnerHTML={{ __html: pdSubtitle }}
          tabIndex={0}
          onKeyDown={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              e.preventDefault();
              showPrescriptionModalPD(true);
            }
            if (e.key == "Escape") {
              showPrescriptionModalPD(false);
            }
          }}
        />
      )}
      <div
        className={`PupillaryDistance__optionsContainer ${showBothPdOptions.value ? "both_values" : ""
          }`}
      >
        {showBothPdOptions.value &&
          (brand === "rayban" ? (
            <div className="PupillaryDistance__optionsContainer__table" tabIndex={0} ref={tableRef} aria-expanded={showBothPdOptions.value} aria-label="two pupillary distance numbers table">
              <div className="PupillaryDistance__optionsContainer__table__leftCol">
                <div className="emptyCell"></div>
                <div className="PupillaryDistance__optionsContainer__table__leftLabel"><span>Pupillary Distance</span></div>
              </div>
              <div className="PupillaryDistance__optionsContainer__table__col">
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div className="PupillaryDistance__checkboxLabel" tabIndex={0}>{pdLeft}</div>
                </div>
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div
                    className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                      }`}
                  >
                    <BrandComponent
                      componentName={"PrescriptionSelect"}
                      parameter={{
                        id: "PD_2",
                        name: "PD_2",
                        title: "PD_2",
                        value: PD2 || "",
                        onChange: (e) => {
                          onPD2Change(parseFloat(e))
                        },
                        hasError:
                          pdValueNotSelectedError && !PD2,
                        options: getSimplePrescriptionValuesArray(
                          Math.floor(prescriptionDefaults.pdMin / 2),
                          Math.floor(prescriptionDefaults.pdMax / 2),
                          enableDigitalOptometry ? 0.5 : prescriptionDefaults.pdRes
                        ).map((e, key) => {
                          return (

                            <option
                              key={key}
                              value={e.value}
                              selected={e.value === PD2}
                            >
                              {e.name}
                            </option>
                          );
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="PupillaryDistance__optionsContainer__table__col">
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div className="PupillaryDistance__checkboxLabel" tabIndex={0}>{pdRight}</div>
                </div>
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div
                    className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                      }`}
                  >
                    <BrandComponent
                      componentName={"PrescriptionSelect"}
                      parameter={{
                        id: "PD_1",
                        name: "PD_1",
                        title: "PD_1",
                        value: PD1 || "",
                        onChange: (e) => {
                          onPD1Change(parseFloat(e))
                        },
                        hasError:
                          pdValueNotSelectedError && !PD1,
                        options: getSimplePrescriptionValuesArray(
                          Math.floor(prescriptionDefaults.pdMin / 2),
                          Math.floor(prescriptionDefaults.pdMax / 2),
                          enableDigitalOptometry ? 0.5 : prescriptionDefaults.pdRes
                        ).map((e, key) => {
                          return (

                            <option
                              key={key}
                              value={e.value}
                              selected={e.value === PD1}
                            >
                              {e.name}
                            </option>
                          );
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : brand === "costa" ? (
            <div className="PupillaryDistance__optionsContainer__table">
              <div className="PupillaryDistance__optionsContainer__table__leftCol">
                <div className="emptyCell"></div>
                <div className="PupillaryDistance__optionsContainer__table__leftLabel"><span>{pupillaryDistanceTitle}</span></div>
              </div>

              <div className="PupillaryDistance__optionsContainer__table__col">
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div className="PupillaryDistance__checkboxLabel">{pdRight}</div>
                </div>
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div
                    className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                      }`}
                  >
                    <BrandComponent
                      componentName={"PrescriptionSelect"}
                      parameter={{
                        id: "PD_1",
                        name: "PD_1",
                        title: "PD_1",
                        value: PD1 || "",
                        onChange: (e) => {
                          onPD1Change(parseFloat(e))
                        },
                        hasError:
                          pdValueNotSelectedError && !PD1,
                        options: getSimplePrescriptionValuesArray(
                          Math.floor(prescriptionDefaults.pdMin / 2),
                          Math.floor(prescriptionDefaults.pdMax / 2),
                          enableDigitalOptometry ? 0.5 : prescriptionDefaults.pdRes
                        ).map((e, key) => {
                          return (

                            <option
                              key={key}
                              value={e.value}
                              selected={e.value === PD1}
                            >
                              {e.name}
                            </option>
                          );
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="PupillaryDistance__optionsContainer__table__col">
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div className="PupillaryDistance__checkboxLabel">{pdLeft}</div>
                </div>
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div
                    className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                      }`}
                  ><BrandComponent
                      componentName={"PrescriptionSelect"}
                      parameter={{
                        id: "PD_2",
                        name: "PD_2",
                        title: "PD_2",
                        value: PD2 || "",
                        onChange: (e) => {
                          onPD2Change(parseFloat(e))
                        },
                        hasError:
                          pdValueNotSelectedError && !PD2,
                        options: getSimplePrescriptionValuesArray(
                          Math.floor(prescriptionDefaults.pdMin / 2),
                          Math.floor(prescriptionDefaults.pdMax / 2),
                          enableDigitalOptometry ? 0.5 : prescriptionDefaults.pdRes
                        ).map((e, key) => {
                          return (

                            <option
                              key={key}
                              value={e.value}
                              selected={e.value === PD2}
                            >
                              {e.name}
                            </option>
                          );
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="PupillaryDistance__optionsContainer__table">
              <div className="PupillaryDistance__optionsContainer__table__leftCol">
                <div className="emptyCell"></div>
                <div className="PupillaryDistance__optionsContainer__table__leftLabel"><span>{pupillaryDistanceTitle}</span></div>
              </div>

              <div className="PupillaryDistance__optionsContainer__table__col">
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div className="PupillaryDistance__checkboxLabel">{pdLeft}</div>
                </div>
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div
                    className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                      }`}
                  ><BrandComponent
                      componentName={"PrescriptionSelect"}
                      parameter={{
                        id: "PD_2",
                        name: "PD_2",
                        title: "PD_2",
                        value: PD2 || "",
                        onChange: (e) => {
                          onPD2Change(parseFloat(e))
                        },
                        hasError:
                          pdValueNotSelectedError && !PD2,
                        options: getSimplePrescriptionValuesArray(
                          Math.floor(prescriptionDefaults.pdMin / 2),
                          Math.floor(prescriptionDefaults.pdMax / 2),
                          enableDigitalOptometry ? 0.5 : prescriptionDefaults.pdRes
                        ).map((e, key) => {
                          return (

                            <option
                              key={key}
                              value={e.value}
                              selected={e.value === PD2}
                            >
                              {e.name}
                            </option>
                          );
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="PupillaryDistance__optionsContainer__table__col">
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div className="PupillaryDistance__checkboxLabel">{pdRight}</div>
                </div>
                <div className="PupillaryDistance__optionsContainer__table__row">
                  <div
                    className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                      }`}
                  >
                    <BrandComponent
                      componentName={"PrescriptionSelect"}
                      parameter={{
                        id: "PD_1",
                        name: "PD_1",
                        title: "PD_1",
                        value: PD1 || "",
                        onChange: (e) => {
                          onPD1Change(parseFloat(e))
                        },
                        hasError:
                          pdValueNotSelectedError && !PD1,
                        options: getSimplePrescriptionValuesArray(
                          Math.floor(prescriptionDefaults.pdMin / 2),
                          Math.floor(prescriptionDefaults.pdMax / 2),
                          enableDigitalOptometry ? 0.5 : prescriptionDefaults.pdRes
                        ).map((e, key) => {
                          return (

                            <option
                              key={key}
                              value={e.value}
                              selected={e.value === PD1}
                            >
                              {e.name}
                            </option>
                          );
                        }),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
        {!showBothPdOptions.value && (
          <>
            <div className="PupillaryDistance__optionsContainer__table single">
              <div className=""><span>{pupillaryDistanceTitle}</span></div>
              <div
                className={`PupillaryDistance__optionsContainer__select_container ${showBothPdOptions.value ? "margin" : ""
                  }`}
              >
                <BrandComponent
                  componentName={"PrescriptionSelect"}
                  parameter={{
                    id: "PD_1",
                    name: "PD_1",
                    title: "PD_1",
                    value: PD1 || "",
                    onChange: (e) => {
                      onPD1Change(parseFloat(e))
                    },
                    hasError:
                      pdValueNotSelectedError && !PD1,
                    options: getSimplePrescriptionValuesArray(
                      Math.floor(prescriptionDefaults.pdMin),
                      Math.floor(prescriptionDefaults.pdMax),
                      enableDigitalOptometry || brand === "clearly" ? 0.5 : prescriptionDefaults.pdRes
                    ).map((e, key) => {
                      return (

                        <option
                          key={key}
                          value={e.value}
                          selected={e.value === PD1}
                        >
                          {e.name + (brand === "opsm" ? ".0" : "")}
                        </option>


                      );
                    }),
                  }}
                />
                {/* <select
                  className={`${showBothPdOptions.value ? "__DoubleSelect" : ""
                    }${pdValueNotSelectedError && !PD1 ? " error" : ""}`}
                  id="PD_1"
                  name="PD_1"
                  title="PD_1"
                  value={PD1 || ""}
                  onChange={(e) => onPD1Change(parseFloat(e.target.value))}
                >
                  {(enableDigitalOptometry || brand === "opsm") && (
                    <option value={undefined}>-</option>
                  )}
                  {getSimplePrescriptionValuesArray(
                    Math.floor(prescriptionDefaults.pdMin),
                    Math.floor(prescriptionDefaults.pdMax),
                    prescriptionDefaults.pdRes
                  ).map((e, key) => {
                    return (
                      <Fragment key={key}>
                        <option
                          key={key}
                          value={e.value}
                          selected={e.value === PD1}
                        >
                          {e.name + (brand === "opsm" ? ".0" : "")}
                        </option>

                        {brand !== "clearly" ? (
                          enableDigitalOptometry && (
                            <option
                              key={key + 10}
                              value={(e.value as number) + 0.5}
                            >
                              {(e.name as number) + 0.5}
                            </option>
                          )
                        ) : (
                          <option
                            key={key + 10}
                            value={(e.value as number) + 0.5}
                          >
                            {(e.name as number) + 0.5}
                          </option>
                        )}
                      </Fragment>
                    );
                  })}
                </select> */}
              </div>
            </div>

          </>
        )}
      </div>

      {brand !== "opsm" && showPDWarning && (
        <BrandComponent
          componentName="WarningBanner"
          parameter={{
            textMobile: PDWarningText,
            text: PDWarningText,
            dataContent: enableDigitalOptometry && checkTargetDigitalOptometry() ? pupillaryDistanceMisurePDAction : "",
            onClick: () =>
              enableDigitalOptometry && checkTargetDigitalOptometry() &&
              reduxDispatch(
                modalsActions.setShowPrescriptionModalPD({
                  show: true,
                  modal: enableDigitalOptometry && checkTargetDigitalOptometry(),
                })
              ),
          }}
        />
      )}
      {pdValueNotSelectedError && (
        <div
          className="PupillaryDistance__pdValueNotSelectedWarning"
          data-content={pupillaryDistanceMisurePDAction}
          onClick={() => showPrescriptionModalPD(true)}
        >
          {pupillaryDistanceWarningValue}
        </div>
      )}
      {showBothPdOptions.value && !readOnly && (
        <div
          className="PupillaryDistance__checkboxContainer"
          style={{ margin: brand === "opsm" ? "0px" : "24px 0px 0px" }}
        >
          <CustomCheckbox value={showBothPdOptions} setter={closeDoublePD} checkboxLabel={iHaveTwoPd} />

          <div className="PupillaryDistance__checkBoxText">{iHaveTwoPd}</div>
        </div>
      )}
      {!showBothPdOptions.value && !readOnly && (
        <div className="PupillaryDistance__checkboxContainer">
          <CustomCheckbox
            value={showBothPdOptions}
            setter={handleCheckboxChange}
            checkboxLabel={iHaveTwoPd}
          />

          <div className="PupillaryDistance__checkBoxText">
            {iHaveTwoPd}
          </div>
        </div>
      )}
      {brand === "opsm" && showPDWarning && (
        <BrandComponent
          componentName="WarningBanner"
          parameter={{
            textMobile: PDWarningText,
            text: PDWarningText,
            dataContent: enableDigitalOptometry && checkTargetDigitalOptometry() ? pupillaryDistanceMisurePDAction : "",
            onClick: () =>
              enableDigitalOptometry && checkTargetDigitalOptometry() &&
              reduxDispatch(
                modalsActions.setShowPrescriptionModalPD({
                  show: true,
                  modal: enableDigitalOptometry && checkTargetDigitalOptometry(),
                })
              ),
          }}
        />
      )}
    </div>
  )
};
