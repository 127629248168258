import React from "react";
import { workflow } from "../../configurator/models/Workflow";
import { useTranslate } from "../../configurator/translations/TranslationHooks";
import { BrandComponent } from "../common/BrandComponent";
import "./default.module.scss";
import { isShowFramePlusLensesPrice } from "../../configurator/ConfigLoader";
import {
  useMixAndMatch,
  useShowFramePlusLensesPrice,
  usePrismPrice,
} from "@hooks";
import { useSelector } from "react-redux";

interface BottomBarTotalPriceProps {
  total: { price: number; discount: number; insPrice?: number };
}

/**
 * Small component that displays the total price and the gst in the bottom bar. It accepts
 * an input of type ```BottomBarTotalPriceProps``` which is an interface defining one
 * parameter:
 *
 * ```typescript
 * {
 *      total: { price: number, discount: number }
 * }
 * ```
 *
 * The component displays these prices using the ```Price``` component.
 */
export function BottomBarTotalPrice({ total }: BottomBarTotalPriceProps) {
  const priceFrameLabel = useTranslate("price.totalFrameLens.frame");
  const priceTotalLabel = useTranslate("price.totalFrameLens.frameLensTotal");
  const bundleFrameLensLabel = useTranslate("price.bundleFrameLensStartingAt");
  const taxLabel = useTranslate("tax");
  const isShow = isShowFramePlusLensesPrice();
  const isMixAndMatch = useMixAndMatch();
  const currentStep = useSelector(
    (state: any) => state.workflow?.currentStep?.progressive
  );
  const lensPrice = useSelector((state: any) => state?.mixAndMatch?.lensPrice);
  const showFramePlusLenses = useShowFramePlusLensesPrice();
  const lensBundleStep = workflow?.stepChain?.find(
    (s) => s.key === "LensBundle"
  )?.progressive;
  const isPrismPriceAdded = useSelector(
    (state: any) => state.prescription?.isPrismPriceAdded
  );
  const prismPrice = usePrismPrice();

  return (
    <div className={"BottomBarTotalPrice"}>
      <div className={"BottomBarTotalPrice__pricerow"}>
        {isPrismPriceAdded && prismPrice ? (
          <p> {priceTotalLabel}:</p>
        ) : showFramePlusLenses && currentStep < lensBundleStep ? (
          <div className={"BottomBarTotalPrice__pricerow__label"}>
            <p style={{ textTransform: "initial" }}>{bundleFrameLensLabel}</p>
          </div>
        ) : (
          <div className={"BottomBarTotalPrice__pricerow__label"}>
            {!(isMixAndMatch && currentStep === 1 && lensPrice) &&
              !workflow.hasManualSelectionBeenDone() ? (
              isShow ? (
                <p> {priceFrameLabel}:</p>
              ) : (
                <></>
              )
            ) : isShow ? (
              <p> {priceTotalLabel}:</p>
            ) : (
              <></>
            )}
          </div>
        )}

        <div className={`BottomBarTotalPrice__pricerow__price ${currentStep === workflow?.stepChain?.find((s) => s.key === "ProtectionPlan")?.progressive
          ? "BottomBarTotalPrice__pricerow__price--red"
          : ""
          }`}>
          {isShow ? (
            <BrandComponent
              componentName="StepPrice"
              parameter={{
                price: total.price,
                discount: total.discount ? total.discount : null,
                insPrice:
                  total.insPrice || total.insPrice === 0
                    ? total.insPrice
                    : null,
                priceStyle: { fontSize: 20, fontFamily: "Lato" },
                hideSign: true,
                ignorePriceLabels: true,
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className={"BottomBarTotalPrice__gstrow"}>{taxLabel}</div>
    </div>
  );
}
